import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Content from '../components/Content'
import SEO from '../components/Seo'

const IndexPage = ({
  data: {
    page: {
      html,
      excerpt,
      frontmatter: { title, subtitle, featured_image: featuredImage },
    },
  },
}) => (
  <Layout>
    <SEO description={excerpt} />
    <Header inverted />
    <Hero
      className="Home--Hero"
      title={title}
      subtitle={subtitle}
      image={featuredImage}
    />
    <section className="section" id="page-main-content">
      <div className="container skinny">
        <div className="taCenter">
          <h3 className="hasBottomBorder">About</h3>
        </div>
        <br />
        <div className="hasColumns">
          <Content src={html} />
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    page: markdownRemark(fields: { slug: { eq: "/" } }) {
      frontmatter {
        title
        subtitle
        featured_image {
          ...LargeImage
        }
      }
      excerpt(pruneLength: 158)
      html
    }
  }
`
