import React from 'react'
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import animateScrollTo from 'animated-scroll-to'

import Image from './Image'
import './Hero.css'

const scrollToPageContent = () => {
  const element = document.querySelector('#page-main-content')
  if (element) {
    animateScrollTo(element)
  }
}

export default ({ className = '', image, title, subtitle }) => (
  <div className={`Hero ${className}`}>
    <div className="Hero--ImageWrap">
      <Image className="Hero-Image" background src={image} alt={title} />
    </div>
    <div className="container skinny Hero--Container">
      <h1 className="Hero--Title hasBottomBorder">{title}</h1>
      <h4 className="Hero--Subtitle">{subtitle}</h4>
    </div>
    <div
      className="Hero--ScrollIndicator"
      role="button"
      aria-label="Scroll down button"
      onClick={scrollToPageContent}
    >
      <ChevronDown />
    </div>
  </div>
)
